<!-- 交通区位 -->
<template>
  <div class="move-frame">
    <div class="body">
      <top showTitle="走进黑水" :childNavs="navs"></top>
      <div class="content">
        <div class="left">
          <img
            class="full"
            src="../../assets/move/fullQueen.png"
            title="全屏"
            alt="全屏"
            @click="setFull(true)"
          />
          <div id="container"></div>
          <div class="types">
            <div class="type" v-for="(item, key) in imageTypes" :key="key">
              <img :src="item" :alt="key" />
              <span>{{ key }}</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="hover-box">
            <video
              v-if="$store.globalSet.publicityVideo"
              class="video"
              :src="URL + $store.globalSet.publicityVideo"
              controls
              controlsList="nodownload"
              preload="auto"
            ></video>
            <div class="desc-bottom">
              <div class="desc" v-html="$store.globalSet.contentStr"></div>
            </div>
          </div>
        </div>
      </div>
      <bottom></bottom>

      <!-- 全屏地图 -->
      <div class="dialog" v-show="isFull">
        <img
          class="full"
          src="../../assets/move/exitFull.png"
          title="退出全屏"
          alt="退出全屏"
          @click="setFull(false)"
        />
        <div id="full"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Bottom from "../../components/bottom.vue";
import top from "../../components/top.vue";
import routes from "../../router/moveWater";
import { URL } from "../../util/config";

import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  components: { top, Bottom },
  data() {
    return {
      URL,
      navs: routes,
      map: null,
      AMap: null,
      fullmap: null, // 全屏地图
      isFull: false, // 判断地图是否全屏
      resourceList: [],
      imageTypes: {
        电力: require("../../assets/move/electric.png"),
        旅游: require("../../assets/move/travel.png"),
        农产品加工: require("../../assets/move/process.png"),
        中药材: require("../../assets/move/herb.png"),
      },
    };
  },
  created() {},
  mounted() {
    this.$api.getAllResource().then((res) => {
      this.resourceList = res.data;

      this.initMap();
    });
  },
  methods: {
    initMap() {
      let that = this;
      AMapLoader.load({
        key: this.$mapKey,
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.DistrictSearch"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          that.AMap = AMap;
          that.map = new AMap.Map("container", {
            //设置地图容器id
            zoom: 14, //初始化地图级别
            center: [102.987592, 32.069268], //初始化地图中心点位置
            layers: [AMap.createDefaultLayer(), new AMap.TileLayer.RoadNet()],
          });
          var opts = {
            subdistrict: 1, //返回下一级行政区
            level: "city", //查询的范围
            showbiz: false, //最后一级返回街道信息
          };
          let district = new AMap.DistrictSearch(opts);
          district.setLevel("黑水县"); //行政区级别
          district.setExtensions("all");
          district.search("513228", function (status, result) {
            if (status == "complete") {
              that.getData(result.districtList[0], "map");
            }
          });

          // 优势资源点位渲染
          that.resourceList.forEach((ele) => {
            const lnglat = ele.longituAndLatitu;
            let path = lnglat ? JSON.parse(lnglat) : [[]];
            const icon = new AMap.Icon({
              // 图标尺寸
              size: new AMap.Size(25, 25),
              // 图标的取图地址
              image: that.imageTypes[ele.resourceType],
              // 图标所用图片大小
              imageSize: new AMap.Size(25, 25),
            });
            let marker = new AMap.Marker({
              position: path[0],
              icon,
              offset: new AMap.Pixel(-12, -12),
              zIndex: 100,
              clickable: true,
            });
            marker.on("click", () => {
              that.openInfo(ele, path[0]);
            });
            marker.setMap(that.map);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //构建信息窗体中显示的内容
    openInfo(data, path) {
      var info = [];
      info.push(
        `<div><div style="font-weight:bold;font-size:18px;margin-bottom:10px;">${data.resourceName}</div>`
      );
      info.push(
        `<img style="width:300px;height:auto" src="${URL + data.imageUrl}"/>`
      );
      info.push(
        `<div style='word-break:break-all;width:300px;max-height:60px;overflow:auto;font-size:14px;'>${data.briefIntroduction}</div>`
      );
      // 有跳转链接再渲染跳转标签
      if (data.jumpUrl) {
        info.push(
          `<div style='text-align:right;'><a href="${data.jumpUrl}" target="_blank" style='color:#0e5acd'>前往查看>></a></div>`
        );
      }
      info.push("</div>");

      let infoWindow = new this.AMap.InfoWindow({
        anchor: "top-center",
        offset: new this.AMap.Pixel(0, 10),
        content: info.join(""), //使用默认信息窗体框样式，显示信息内容
      });

      infoWindow.open(this.map, path);
    },
    getData(data, type) {
      let map = this[type];
      var bounds = data.boundaries;
      let polygons = [];
      if (bounds) {
        for (var i = 0, l = bounds.length; i < l; i++) {
          var polygon = new this.AMap.Polygon({
            map,
            strokeWeight: 1,
            strokeColor: "#0091ea",
            fillColor: "#80d8ff",
            fillOpacity: 0.2,
            path: bounds[i],
          });
          polygons.push(polygon);
        }
        this[type].setFitView(); //地图自适应
      }
    },
    // 控制地图全屏的开启关闭
    setFull(bol) {
      this.isFull = bol;
      !this.fullmap && this.initFullMap();
    },
    initFullMap() {
      let that = this;
      AMapLoader.load({
        key: this.$mapKey,
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale", "AMap.ToolBar", "AMap.DistrictSearch"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          that.fullmap = new AMap.Map("full", {
            //设置地图容器id
            zoom: 14, //初始化地图级别
            center: [102.987592, 32.069268], //初始化地图中心点位置
            layers: [AMap.createDefaultLayer(), new AMap.TileLayer.RoadNet()],
          });
          // 地图控件
          that.fullmap.addControl(new AMap.Scale());
          that.fullmap.addControl(new AMap.ToolBar());
          // 行政区标记
          var opts = {
            subdistrict: 1, //返回下一级行政区
            level: "city", //查询的范围
            showbiz: false, //最后一级返回街道信息
          };
          let district = new AMap.DistrictSearch(opts);
          district.setLevel("黑水县"); //行政区级别
          district.setExtensions("all");
          district.search("513228", function (status, result) {
            if (status == "complete") {
              that.getData(result.districtList[0], "fullmap");
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  },
};
</script>

<style lang='scss' scoped>
.dialog {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2000;

  .full {
    width: 30px;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 10;
    cursor: pointer;
  }
  #full {
    width: 100%;
    height: 100%;
  }
}

.content {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;

  .left {
    flex-grow: 1;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba($color: #aaa, $alpha: 0.3);
    position: relative;
  }

  .full {
    width: 30px;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 10;
    cursor: pointer;
  }

  #container {
    width: 100%;
    height: 100%;
  }

  .types {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 5px #ececec;
    padding: 5px;
    border-radius: 5px;

    .type {
      &:not(:first-child) {
        margin-left: 20px;
      }
      img {
        width: 20px;
        vertical-align: middle;
      }
      span {
        margin-left: 5px;
        font-size: 14px;
      }
    }
  }

  .right {
    width: 550px;
    height: 100%;
    margin-left: 10px;
  }
  .hover-box {
    width: 100%;
    height: 100%;
    font-size: 13px;

    .video {
      width: 100%;
      max-height: 300px;
      background-color: #fff;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 10px;
    }
    .desc-bottom {
      height: calc(100% - 355px);
      background-color: #fff;
      overflow: auto;
      padding: 20px;
    }
  }
}
@media (max-width: 1700px) {
  .content .right {
    width: 450px;
  }
}
@media (max-width: 1500px) {
  .content .right {
    width: 360px;

    .hover-box .video {
      max-height: 250px;
    }
    .hover-box .desc-bottom {
      height: calc(100% - 305px);
    }
  }
}
</style>
